export const setCookieUser = (data) => {
    sessionStorage.setItem('user_id', data.userId);
    sessionStorage.setItem('true_name', data.trueName);
    sessionStorage.setItem('user_name', data.userName);
    // sessionStorage.setItem('user_type', data.user_type);
};
export const setCookieToten = (data) => {
    sessionStorage.setItem('access_token', data.access_token);
};

export const setCookieAccessToten = (access_token) => {
    sessionStorage.setItem('access_token', access_token);
};

export const getCookieAccessToken = function() {
    return sessionStorage.getItem('access_token');
};
export const getCookieOpenId = function() {
    return sessionStorage.getItem('open_id');
};
export const getCookieUID = function() {
    return sessionStorage.getItem('user_id');
};
export const getCookieUNAME = function() {
    return sessionStorage.getItem('user_name');
};
export const getCookieUTYPE = function() {
    return sessionStorage.getItem('user_type');
};
export const getCookieTNAME = function() {
    return sessionStorage.getItem('true_name');
};
export const clearCookie = function() {
    return sessionStorage.clear();
};
