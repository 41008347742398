<template>
    <div class="home">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <Row justify="start">
            <Form ref="queryParams" :model="queryParams" inline>
                <i-col span="6" class-name="searchRow">
                    <FormItem label="关键字" prop="keyWord">
                        <i-input v-model="queryParams.maybeStr" clearable placeholder="患者姓名、手机号码"></i-input>
                    </FormItem>
                </i-col>
                <i-col span="7" class-name="searchRow">
                    <FormItem label="学员类型">
                        <Select v-model="queryParams.memberLevel">
                            <Option :value="0">普通学员</Option>
                            <Option :value="1">VIP学员</Option>
                        </Select>
                    </FormItem>
                </i-col>
                <i-col span="10" class-name="searchRow">
                    <FormItem label="时间筛选">
                        <Row>
                            <i-col span="11">
                                <FormItem prop="date">
                                    <DatePicker type="date" placeholder="开始时间" v-model="queryParams.trainStartDate"
                                                @on-change="changStartTime"></DatePicker>
                                </FormItem>
                            </i-col>
                            <i-col span="2" style="text-align: center">-</i-col>
                            <i-col span="11">
                                <FormItem prop="time">
                                    <DatePicker type="date" placeholder="结束时间" v-model="queryParams.trainEndDate"
                                                @on-change="changEndTime"></DatePicker>
                                </FormItem>
                            </i-col>
                        </Row>
                    </FormItem>
                </i-col>
                <i-col span="8" class-name="searchRow">
                    <FormItem>
                        <Button type="primary" icon="ios-search" @click="refreshTable">查询</Button>
                        <Button type="primary" icon="md-refresh" ghost
                                style="margin-left: 20px;background: rgba(232, 240, 255, 1)"
                                @click="handleReset('queryParams')">重置
                        </Button>
                    </FormItem>
                </i-col>
            </Form>

        </Row>
        <Row :gutter="16">
            <i-col span="24">
                <Table border stripe :columns="tableColumns" :data="tableData" :loading="tableLoading"></Table>
            </i-col>
        </Row>
        <br>
        <Row :gutter="16" type="flex" justify="end">
            <i-col span="15">
                <Page :total="queryParams.totalRecord"
                      :current="queryParams.pageNumber+1"
                      :page-size="queryParams.pageSize"
                      @on-change="pIndexChange"
                      @on-page-size-change="pSizeChange"
                      show-sizer
                      show-total
                      show-elevator/>
            </i-col>
        </Row>
        <Modal title="训练详情" v-model="editModal" class-name="vertical-center-modal patientDate"
               ok-text="保存" :footer-hide=true
               :mask-closable="false" width="1200">
            <Row justify="start">
                <Form :model="queryParams2" inline ref="queryParams2">
                    <i-col span="6" class-name="searchRow">
                        <FormItem label="关键字">
                            <i-input v-model="queryParams2.trainName" clearable placeholder="游戏名称"></i-input>
                        </FormItem>
                    </i-col>
                    <i-col span="4" class-name="searchRow">
                        <FormItem label="领域名称" prop="typeId">
                            <Select v-model="queryParams2.typeId">
                                <Option :value="1">记忆力</Option>
                                <Option :value="2">注意力</Option>
                                <Option :value="3">执行功能</Option>
                                <Option :value="4">逻辑推理</Option>
                            </Select>

                        </FormItem>
                    </i-col>
                    <i-col span="6" class-name="searchRow">
                        <FormItem>
                            <Button type="primary" icon="ios-search" @click="refreshTable2">查询</Button>
                            <Button type="primary" icon="md-refresh" ghost
                                    style="margin-left: 20px;background: rgba(232, 240, 255, 1)"
                                    @click="handleReset2('queryParams2')">重置
                            </Button>
                        </FormItem>
                    </i-col>
                </Form>

            </Row>
            <Row :gutter="16">
                <i-col span="24">
                    <Table border stripe :columns="tableColumns2" :data="tableData2"></Table>
                </i-col>
            </Row>
            <br>
            <Row :gutter="16" type="flex" justify="end">
                <i-col span="15">
                    <Page :total="queryParams2.totalRecord"
                          :current="queryParams2.pageNumber+1"
                          :page-size="queryParams2.pageSize"
                          @on-change="pIndexChange2"
                          @on-page-size-change="pSizeChange2"
                          show-sizer
                          show-total
                          show-elevator/>
                </i-col>
            </Row>
        </Modal>
    </div>
</template>
<script>
    import { getCookieUID, getCookieUNAME } from '@js/session.sg';
    import Breadcrumb from '@/components/Breadcrumb';

    export default {
        components: {
            Breadcrumb
        },
        data() {
            return {
                crumbData: [
                    {
                        path: '',
                        name: '训练列表'
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    maybeStr: "",
                    trainStartDate: "",
                    trainEndDate: "",
                    memberLevel: "",
                },
                tableColumns: [
                    {
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            return h("span", {}, params.index + 1 + this.queryParams.pageNumber * this.queryParams.pageSize)
                        }
                    },
                    {
                        title: '患者名称',
                        align: 'center',
                        key: 'nickName'
                    },
                    {
                        title: '手机号',
                        align: 'center',
                        width: 150,
                        key: 'phoneNumber'
                    },
                    {
                        title: '学员类型',
                        align: 'center',
                        key: 'memberLevel',
                        render: (h, params) => {
                            switch (params.row.memberLevel) {
                                case 0:
                                    return h("span", "普通学员");
                                case 1:
                                    return h("span", "VIP学员");
                                default:
                                    return h("strong", "--");
                            }
                        }
                    },
                    {
                        title: '方案类别',
                        align: 'center',
                        width: 180,
                        key: 'planName',
                        render: (h, params) => {
                            if (params.row.planName === null || params.row.planName === "") {
                                return h("strong", "--");
                            } else {
                                return h("span", params.row.planName);
                            }

                        }
                    },
                    {
                        title: '训练开始时间',
                        align: 'center',
                        width: 180,
                        key: 'trainStartDate'
                    },
                    {
                        title: '训练结束时间',
                        align: 'center',
                        width: 180,
                        key: 'trainEndDate'
                    },
                    {
                        title: '完成状态',
                        align: 'center',
                        key: 'finishStatus',
                        render: (h, params) => {
                            switch (params.row.finishStatus) {
                                case 0:
                                    return h("span", "未完成");
                                case 1:
                                    return h("span", "已完成");
                                default:
                                    return h("strong", "--");
                            }
                        }
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 100,
                        align: 'center',
                        render: (h, params) => {
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.toEdit(params.row.trainLogId)
                                        }
                                    }
                                }, '详情'),
                            ]);
                        }
                    }
                ],
                tableData: [],
                queryParams2: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    trainName: '',
                    trainLogId: '',
                    typeId: '',
                },
                tableColumns2: [
                    {
                        title: '序号',
                        width: 70,
                        align: 'center',
                        render: (h, params) => {
                            return h("span", {}, params.index + 1 + this.queryParams2.pageNumber * this.queryParams2.pageSize)
                        }
                    },
                    {
                        title: '领域名称',
                        align: 'center',
                        key: 'typeName',
                        width: 110,
                    },
                    {
                        title: '游戏名称',
                        align: 'center',
                        key: 'trainName'
                    },
                    {
                        title: '最高等级',
                        align: 'center',
                        key: 'trainLevel',
                        width: 100,
                    },
                    {
                        title: '训练开始日期',
                        align: 'center',
                        key: 'trainStartDate'
                    },
                    {
                        title: '训练结束日期',
                        align: 'center',
                        key: 'trainEndDate'
                    },
                    {
                        title: '训练时长（分钟）',
                        align: 'center',
                        key: 'trainTimes'
                    },
                    {
                        title: '本次得分',
                        align: 'center',
                        key: 'trainScore',
                        width: 100,
                    },
                ],
                tableData2: [],
                tableLoading: false,
                editModal: false,
            }
        },
        mounted() {
            this.initTable();
        },
        methods: {
            // 开始时间选中
            changStartTime(value) {
                this.queryParams.trainStartDate = value;
            },
            // 结束时间选择
            changEndTime(value) {
                this.queryParams.trainEndDate = value;
            },
            initTable: function () {
                this.tableLoading = true;
                this.$http.gets('/backstage/plan/logs/getUserPlanTrainLogsListPage', this.queryParams)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            // todo
                            this.tableData = res.data.list;
                            this.queryParams.totalRecord = res.data.total;
                            this.tableLoading = false;
                        } else {
                            this.$Message.error(res.msg);
                            this.tableLoading = false;
                        }
                    }).catch(err => {
                    this.tableLoading = false;
                    console.log(err);
                });
            },
            // 重置
            handleReset(name) {
                this.$refs[name].resetFields();
                this.queryParams.trainStartDate = '';
                this.queryParams.trainEndDate = '';
                this.queryParams.maybeStr = '';
                this.queryParams.memberLevel = '';
            },
            // 重置
            handleReset2(name) {
                this.$refs[name].resetFields();
                this.queryParams2.trainName = '';
                this.queryParams2.typeId = '';
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            pIndexChange: function (page) {
                this.queryParams.pageNumber = page - 1;
                this.initTable();
            },
            pSizeChange: function (size) {
                this.queryParams.pageSize = size;
                this.initTable();
            },

            toEdit: function (trainLogId) {
                this.queryParams2.trainLogId = trainLogId;
                this.getTable2();
            },
            getTable2() {
                let data = this.queryParams2;
                this.$http.gets('/backstage/plan/logs/getUserPlanTrainLogsDetail',
                    this.queryParams2
                )
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            // todo
                            this.tableData2 = res.data;
                            this.queryParams2.totalRecord = this.tableData2.length;
                            this.editModal = true;
                        } else {
                            this.$Message.error(res.msg);
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            refreshTable2: function () {
                this.queryParams2.pageNumber = 0;
                this.getTable2();
            },
            pIndexChange2: function (page) {
                this.queryParams2.pageNumber = page - 1;
                this.getTable2();
            },
            pSizeChange2: function (size) {
                this.queryParams2.pageSize = size;
                this.getTable2();
            },
        }
    }
</script>
<style scoped lang="scss">
    .home {
        height: 100%;
        width: 100%;
    }
    ::v-deep .ivu-select-selection{
        width: 100px;
    }
    /*修改 表单间距*/
    ::v-deep .ivu-modal-header-inner{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ::v-deep .searchRow {
        .ivu-form-item {
            display: flex;
            align-items: center;

            .ivu-form-item-label {
                text-align: left;
                padding: 0;
                margin-right: 10px;
                white-space: nowrap;
            }
        }
    }

    ::v-deep .patientDate {
        .ivu-modal-body {
            height: 600px;
            overflow: auto;
        }
    }
</style>
