<template>
    <Breadcrumb>
<!--        <BreadcrumbItem to="/">Home</BreadcrumbItem>-->
<!--        <BreadcrumbItem to="/components/breadcrumb">Components</BreadcrumbItem>-->
<!--        <BreadcrumbItem>Breadcrumb</BreadcrumbItem>-->

        <BreadcrumbItem v-for=" (item, idx) in crumbData" :to="item.path" :key="idx">{{item.name}}</BreadcrumbItem>

    </Breadcrumb>
</template>
<script>
    export default {
        props: ['crumbData']
    }
</script>
<style lang="less" scoped>
    .ivu-breadcrumb {
        line-height: 20px;
        font-size: 16px;
        padding-bottom: 15px;
    }
</style>
